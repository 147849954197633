import { type ReactElement } from 'react';

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: ReactElement) => JSX.Element;
  children: ReactElement;
}): ReactElement => (condition ? wrapper(children) : children);

export default ConditionalWrapper;
